import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/components/home/AppDealHome'),
    meta: { title: 'Home' },
  },
  {
    path: '/deal-filter/app',
    name: 'deal-filter-app',
    component: () => import('@/components/dealFilter/page/DealFilterMobile'),
    meta: { title: 'App Deal Filter' },
  },
  {
    path: '/deal-filter/app-desktop',
    name: 'deal-filter-desktop',
    component: () => import('@/components/dealFilter/page/DealFilterDesktop'),
    meta: { title: 'App Deal Filter' },
  },
  {
    path: '/product-page',
    name: 'product-page',
    meta: { title: 'Product' },
    redirect: () => {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if(isMobile) {
        return { path: '/product-page/app' };
      } else {
        return {path: '/product-page/app-desktop'};
      }
    },
  },
  {
    path: '/product-page/app',
    name: 'product-page-app',
    component: () => import('@/components/hotelFlight/page/ProductPageApp'),
    meta: { title: 'Hotel Flight' },
  },
  {
    path: '/product-page/app-desktop',
    name: 'product-page-app-desktop',
    component: () => import('@/components/hotelFlight/page/ProductPageAppDesktop'),
    meta: { title: 'Hotel Flight' },
  },
  // {
  //   path: '/product-hotelbeds/app',
  //   name: 'product-hotelbeds-app',
  //   component: () => import('@/components/hotelFlight/hotelbeds/HBProductPageApp'),
  //   meta: { title: 'Product Page' },
  // },
  // {
  //   path: '/product-hotelbeds/app-desktop',
  //   name: 'product-hotelbeds-app-desktop',
  //   component: () => import('@/components/hotelFlight/hotelbeds/HBProductPageAppDesktop'),
  //   meta: { title: 'Product Page' },
  // },
  {
    path: '/flight-only',
    name: 'flight-only',
    meta: { title: 'Flight Only' },
    redirect: () => {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if(isMobile) {
        return { path: '/flight-only/app' };
      } else {
        return {path: '/flight-only/app-desktop'};
      }
    },
  },
  {
    path: '/flight-only/app',
    name: 'flight-only-app',
    component: () => import('@/components/flightOnly/page/FlightOnlyApp'),
    meta: { title: 'Flight Only' },
  },
  {
    path: '/flight-only/app-desktop',
    name: 'flight-only-app-desktop',
    component: () => import('@/components/flightOnly/page/FlightOnlyAppDesktop'),
    meta: { title: 'Flight Only' },
  },
  {
    path: '/booking',
    name: 'booking',
    component: () => import('@/components/booking/Booking'),
    meta: { title: 'Booking Page' },
    children: [
      {
        path: 'hotel-flight/app',
        component: () => import('@/components/booking/hotelFlight/HotelFlightApp'),
        name: 'booking-hotel-flight-app',
        meta: { title: 'Booking Hotel & Flight' },
      },
      {
        path: 'hotel-flight/app-desktop',
        component: () => import('@/components/booking/hotelFlight/HotelFlightAppDesktop'),
        name: 'booking-hotel-flight-app-desktop',
        meta: { title: 'Booking Hotel & Flight' },
      },
      {
        path: 'flight/app',
        component: () => import('@/components/booking/flight/FlightApp'),
        name: 'booking-flight-app',
        meta: { title: 'Booking Flight' },
      },
      {
        path: 'flight/app-desktop',
        component: () => import('@/components/booking/flight/FlightAppDesktop'),
        name: 'booking-flight-app-desktop',
        meta: { title: 'Booking Flight' },
      },
      {
        path: 'verifying-card',
        component: () => import('@/components/booking/VerifyingCard'),
        name: 'verifying-card',
        meta: { title: 'Verifying Card' },
      },
      {
        path: 'card-verified',
        name: 'card-verified',
        meta: { title: 'Card Verified' },
        redirect: () => {
          const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
          if(isMobile) {
            return { path: '/booking/card-verified/app' };
          } else {
            return { path: '/booking/card-verified/app-desktop' };
          }
        },
      },
      {
        path: 'card-verified/app',
        component: () => import('@/components/booking/cardVerified/CardVerifiedApp'),
        name: 'card-verified-app',
        meta: { title: 'Card Verified' },
      },
      {
        path: 'card-verified/app-desktop',
        component: () => import('@/components/booking/cardVerified/CardVerifiedAppDesktop'),
        name: 'card-verified-app-desktop',
        meta: { title: 'Card Verified' },
      },
    ],
  },
  {
    path: '/redirect-verified',
    component: () => import('@/components/booking/Redirect'),
    name: 'redirect-verified',
    meta: { title: 'Card Verified' },
  },
  {
    path: '/error-404',
    name: '404',
    component: () => import('@/components/404'),
    meta: { title: '404' },
  },
  {
    path: '/:slug',
    name: 'slug-info',
    component: () => import('@/components/travelInfo/TravelInfo'),
    // meta: { title: 'info' },
    meta: {
      title: 'info',
      sitemap: {
        slugs: [
          '%D7%A6%D7%95%D7%A8_%D7%A7%D7%A9%D7%A8',
          '%D7%94%D7%A8%D7%A9%D7%9E%D7%94_%D7%9C%D7%A8%D7%A9%D7%99%D7%9E%D7%AA_%D7%AA%D7%A4%D7%95%D7%A6%D7%94',
          '%D7%91%D7%A7%D7%A9%D7%94_%D7%9C%D7%91%D7%99%D7%98%D7%95%D7%9C_%D7%94%D7%96%D7%9E%D7%A0%D7%94',
          '%D7%9E%D7%93%D7%A8%D7%99%D7%9A_%D7%94%D7%99%D7%A2%D7%93%D7%99%D7%9D',
          'D7%90%D7%95%D7%93%D7%95%D7%AA_',
          '%D7%9E%D7%93%D7%99%D7%A0%D7%99%D7%95%D7%AA_%D7%A4%D7%A8%D7%98%D7%99%D7%95%D7%AA',
          '%D7%9E%D7%99%D7%93%D7%A2_%D7%9C%D7%A0%D7%95%D7%A1%D7%A2',
          '%D7%AA%D7%95%D7%91%D7%A2%D7%A0%D7%95%D7%AA_%D7%99%D7%99%D7%A6%D7%95%D7%92%D7%99%D7%AA',
          '%D7%AA%D7%A7%D7%A0%D7%95%D7%9F_%D7%91%D7%99%D7%98%D7%95%D7%9C_%D7%95%D7%96%D7%99%D7%9B%D7%95%D7%99',
          '%D7%97%D7%95%D7%A7_%D7%A9%D7%A8%D7%95%D7%AA%D7%99_%D7%94%D7%AA%D7%A2%D7%95%D7%A4%D7%94',
          '%D7%AA%D7%A7%D7%A0%D7%95%D7%9F_%D7%9B%D7%A8%D7%98%D7%99%D7%A1%D7%99_%D7%98%D7%99%D7%A1%D7%94',
          '%D7%AA%D7%A0%D7%90%D7%99%D7%9D_%D7%95%D7%90%D7%97%D7%A8%D7%99%D7%95%D7%AA_%D7%91%D7%98%D7%99%D7%95%D7%9C%D7%99%D7%9D_%D7%9E%D7%90%D7%95%D7%A8%D7%92%D7%A0%D7%99%D7%9D',
          '%D7%AA%D7%A0%D7%90%D7%99%D7%9D_%D7%9B%D7%9C%D7%9C%D7%99%D7%9D_%D7%95%D7%94%D7%92%D7%91%D7%9C%D7%AA_%D7%90%D7%97%D7%A8%D7%99%D7%95%D7%AA',
          '%D7%AA%D7%A7%D7%A0%D7%95%D7%9F_FLYING_SAFE',
          '%D7%94%D7%A0%D7%97%D7%99%D7%95%D7%AA_%D7%9C%D7%99%D7%95%D7%A6%D7%90%D7%99%D7%9D_%D7%9E%D7%99%D7%A9%D7%A8%D7%90%D7%9C_%D7%95%D7%9C%D7%97%D7%95%D7%96%D7%A8%D7%99%D7%9D_%D7%9C%D7%99%D7%A9%D7%A8%D7%90%D7%9C',
          '%D7%91%D7%98%D7%95%D7%9E%D7%99_%D7%A7%D7%A0%D7%99%D7%95%D7%AA_%D7%91%D7%91%D7%98%D7%95%D7%9E%D7%99_%D7%94%D7%A9%D7%98%D7%99%D7%97_%D7%94%D7%9E%D7%A2%D7%95%D7%A4%D7%A3',
          '%D7%93%D7%99%D7%9C%D7%99%D7%9D_%D7%9C%D7%91%D7%98%D7%95%D7%9E%D7%99',
          '%D7%97%D7%91%D7%99%D7%9C%D7%95%D7%AA_%D7%A0%D7%95%D7%A4%D7%A9_%D7%9C%D7%90%D7%A0%D7%98%D7%9C%D7%99%D7%94',
          '%D7%98%D7%99%D7%95%D7%9C%D7%99%D7%9D_%D7%9E%D7%90%D7%95%D7%A8%D7%92%D7%A0%D7%99%D7%9D_%D7%9C%D7%90%D7%99%D7%A8%D7%95%D7%A4%D7%94',
          '%D7%97%D7%91%D7%99%D7%9C%D7%95%D7%AA_%D7%A0%D7%95%D7%A4%D7%A9_%D7%9C%D7%9B%D7%A8%D7%AA%D7%99%D7%9D',
          '%D7%A7%D7%A0%D7%99%D7%95%D7%AA_%D7%91%D7%A0%D7%90%D7%A4%D7%95%D7%9C%D7%99',
          '%D7%95%D7%A8%D7%A0%D7%94_%D7%97%D7%91%D7%99%D7%9C%D7%95%D7%AA_%D7%A0%D7%95%D7%A4%D7%A9_%D7%9C%D7%95%D7%A8%D7%A0%D7%94_%D7%94%D7%A9%D7%98%D7%99%D7%97_%D7%94%D7%9E%D7%A2%D7%95%D7%A4%D7%A3',
          '%D7%93%D7%99%D7%9C%D7%99%D7%9D_%D7%9C%D7%90%D7%99%D7%A8%D7%95%D7%A4%D7%94',
          '%D7%97%D7%91%D7%99%D7%9C%D7%95%D7%AA_%D7%A1%D7%95%D7%A4%D7%95%D7%A8%D7%98',
          '%D7%AA%D7%A7%D7%A0%D7%95%D7%9F_%D7%9E%D7%95%D7%A2%D7%93%D7%95%D7%9F_%D7%9C%D7%A7%D7%95%D7%97%D7%95%D7%AA_%D7%94%D7%90%D7%AA%D7%A8',
        ],
      },
    },
  },
  {
    path: '/*',
    name: 'notFound',
    component: () => import('@/components/404'),
    meta: { title: '500' },
  },
];

export default new Router({
  mode: 'history',
  routes,
});
