<template>
  <div id="app" :class="[lang, isAgencyAgentLogin && 'has-background', kindBonauf]" :style="{backgroundColor: backgroundColor}">
    <Container v-if="categoryList && !!whiteLabel" />
    <ContentLoading
      v-if=" ((!categoryList || categoryList && categoryList.length == 0) && isAgency ) || isProcessingBooking"
      type="app"
    />
    <b-modal
      id="processingBooking"
      centered
      size="lg"
      :dir="lang === 'he' ? 'rtl' : 'ltr'"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-footer="true"
    >
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline" :dir="lang==='he' ? 'rtl' : 'ltr'">
          <h3>{{ $t('booking.waiting') }}</h3>
        </div>
      </template>
      <div class="d-flex">
        <h5>{{ $t('booking.waiting-populating') }}</h5>
        <img src="/assets/img/waiting.gif" width="100" height="100" alt="waiting"/>
      </div>
    </b-modal>
    <b-modal
      id="statusSite"
      centered
      size="lg"
      :dir="lang === 'he' ? 'rtl' : 'ltr'"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-footer="true"
      :title="statusTitle"
    >
      <div :class="[lang == 'he' ? 'text-right' : 'text-left']" :dir="lang==='he' ? 'rtl' : 'ltr'" v-html="statusMessage"></div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';
import { BModal } from 'bootstrap-vue';
import dayjs from 'dayjs';
import googleAnalytics from '@/utils/googleAnalytics';
import bannerImageDestination from '@/utils/bannerImageDestination';
import imageUrlMixin from '@/utils/imageUrlMixin';
// import { getTypeStatusSite } from '@/utils/constant';

const { VUE_APP_LANDING_PAGE_KEY, VUE_APP_CHANNEL_MOBILE_APP, VUE_APP_STAGING_DOMAIN } = process.env;

export default {
  name: 'App',
  components: {
    BModal,
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    Container: () => import('@/components/Container'),
  },
  mixins: [googleAnalytics, imageUrlMixin, bannerImageDestination],
  metaInfo() {
    const { name, query } = this.$route;
    const metaInfoData = [];
    let title = '', destination = '', dealType = '', departure = '', hotel = '';
    const production = JSON.parse(window.sessionStorage.getItem('production'));
    if (production) {
      dealType = this.getDealTypeName(window.sessionStorage.getItem('dealType'));
      departure = dayjs(production.fromDate).format('YYYY-MM-DD');
      destination = production.destination_1_name;
      title = `   ${dealType} ל ${destination} - ${departure}`;
      if (production?.translations?.hotelName && (name === 'booking-hotel-flight' || name === 'booking-flight' || name === 'verifying-card' || name === 'card-verified')) {
        // hotel = production?.translations?.hotelName?.[production.hotel[0].hotelId]?.en || production?.translations?.hotelName?.[production.hotel[0].hotelId]?.he;
        const hotelId = Number(production.hotels[0].La_ID);
        hotel = production?.translations?.hotelName?.[hotelId]?.en || production?.translations?.hotelName?.[hotelId]?.he;
        title += ` - ${hotel}`;
      }
    }

    let destList = [], strDestination = ''; // hotel list
    switch (name) {
      case 'home': case 'slug-info':
        destination = query[VUE_APP_LANDING_PAGE_KEY];
        if (destination === undefined || !destination) {
          title = 'בוא נעוף-דילים מטורפים בכל יום';
          window.prerenderReady = true;
        } else {
          destination = this.landingDealData[0]?.destinationName?.[this.lang];
          title = `השטיח המעופף חבילות נופש ל ${destination}`;
          if (this.landingDealData[0]?.destinationName) { window.prerenderReady = true; }
        }
        break;
      case 'booking-hotel-flight': case 'booking-flight':
        title = `השטיח המעופף עמוד תשלום${title}`;
        window.prerenderReady = true;
        break;
      case 'verifying-card':
        title = `השטיח המעופף  עמוד חיוב${title}`;
        window.prerenderReady = true;
        break;
      case 'card-verified':
        title = `השטיח המעופף  עמוד אישור${title}`;
        window.prerenderReady = true;
        break;
      case 'destination-list':
        if (query.packCategory === 'vacation_pack') {
          title = 'השטיח המעופף | מדריך היעדים';
        }
        if (query.packCategory === 'Flight_Only') {
          title = 'השטיח המעופף | טיסות';
        }
        if (query.packCategory === 'Organize_tour_packages') {
          title = 'השטיח המעופף | טיולים מאורגנים';
        }
        window.prerenderReady = true;
        break;
      case 'hotel-list':
        destList = decodeURIComponent(query.dest).split(',') ?? [];
        strDestination = destList.map((dest) => this.listDestinations?.[query.packCategory]?.find((d) => (d.code === dest))?.name?.he ?? dest).join(',');
        if (query.packCategory === 'vacation_pack') {
          title = `השטיח המעופף | מלונות ב ${strDestination}`;
        }
        if (query.packCategory === 'Flight_Only') {
          title = `השטיח המעופף | טיסות ל ${strDestination}`;
        }
        if (query.packCategory === 'Organize_tour_packages') {
          title = `השטיח המעופף | טיולים מאורגנים ${strDestination}`;
        }
        if (this.listDestinations) { window.prerenderReady = true; }
        break;
      case 'shareAppLink': case 'home-app': case 'deal-filter-app': case 'deal-tab-app':
      case 'booking-hotel-flight-app': case 'booking-flight-app': case 'card-verified-app':
      case 'deal-filter-desktop': case 'deal-tab-desktop':
        title = 'בוא נעוף-דילים מטורפים בכל יום';
        // metaInfoData.push({ name: 'description', content: 'בכל יום דיל חדש, בכל יום הזדמנות חדשה לחופשה|טיסות ישירות וחבילות נופש ליעדים הכי חמים שיש' });
        // metaInfoData.push({ property: 'og:description', content: 'ברוכים הבאים לדף הרשמי של אפליקציית בוא נעוף. כל מה שנשאר לכם לעשות הוא להוריד את האפליקציה ולבחור בימי רביעי...' });
        // metaInfoData.push({ property: 'og:title', content: 'בוא נעוף אפליקציה - אפליקציית תיירות, הדף הרשמי' });
        // metaInfoData.push({ property: 'og:site_name', content: 'בוא נעוף אפליקציה - אפליקציית תיירות, הדף הרשמי' });
        // metaInfoData.push({ name: 'keywords', content: 'בא נעוף' });
        // console.log(metaInfoData);
        window.prerenderReady = true;
        break;
      case 'search-result':
        destList = decodeURIComponent(query.dest).split(',') ?? [];
        dealType = this.getDealTypeName(query.categoryId);
        destination = destList.map((dest) => this.category?.destinations?.find((item) => item.code === dest)?.name[this.lang] || dest).join(',');
        title = `תוצאות חיפוש: ${dealType} ${destination} ${query.fromDate} ~ ${query.toDate}`;

        document.querySelector('head meta[property="og:image"]').setAttribute('content', this.bannerImage);
        if (this.category) { window.prerenderReady = true; }
        break;
      case 'product-page': case 'product-page-web': case 'product-page-old-app': case 'product-page-app': case 'product-page-app-desktop':
        destination = this.product?.destination_1_name || '';
        dealType = this.getDealTypeName(query.categoryId);
        departure = query.dateFrom;
        hotel = this.product?.translations?.hotelName?.[query.laId]?.en || this.product?.translations?.hotelName?.[query.laId]?.he;
        title = `השטיח המעופף   ${dealType} ל ${destination} - ${departure} - ${hotel}`;

        document.querySelector('head meta[property="og:title"]').setAttribute('content', title);
        document.querySelector('head meta[property="og:description"]').setAttribute('content', destination);
        document.querySelector('head meta[property="og:image"]').setAttribute('content', this.product?.perspectiveUrls?.[0] || this.product?.galleryUrls?.[0] || this.product?.productContent?.imageUrls?.[0] || this.bannerImage);
        // if (this.product) { window.prerenderReady = true; }
        break;
      case 'flight-only': case 'flight-only-web': case 'flight-only-old-app': case 'flight-only-app': case 'flight-only-app-desktop':
        destination = this.product?.destination_1_name || '';
        dealType = this.getDealTypeName(query.categoryId);
        departure = query.dateFrom;
        title = `השטיח המעופף   ${dealType} ל ${destination} - ${departure}`;

        document.querySelector('head meta[property="og:image"]').setAttribute('content', this.bannerImage);
        // if (this.product) { window.prerenderReady = true; }
        break;
      default:
    }

    if (this.marketerId !== '') {
      title = this.agencyMarketerContent?.metaTitle ?? title;
      // this.setBackgroundColor(this.agencyMarketerContent?.backgroundColorCss ?? 'white');
    } else if (this.whiteLabel && !this.whiteLabel.forMainSite) {
      title = this.whiteLabel?.metaTitle ?? title;
      // this.setBackgroundColor(this.whiteLabel?.backgroundColorCss ?? 'white');
    }

    document.title = title;
    const origin = window.location.href;
    document.querySelector('head meta[property="og:url"]').setAttribute('content', origin);

    if (this.isDesktopApp) {
      // document.querySelector('head meta[property="og:title"]').setAttribute('content', 'בוא נעוף-דילים מטורפים בכל יום');
      // document.querySelector('head meta[property="og:site_name"]').setAttribute('content', 'בוא נעוף - דילים מטורפים בכל יום');
      // document.querySelector('head meta[name="keywords"]').setAttribute('content', 'כל יום דיל חדש  ,כל יום יעד חדש  ,  בוא נעוף');
      // document.querySelector('#icon-tab').href = 'https://flying.co.il/assets/img/bonaufLink.png';

      // const bonaufAppDescription = 'בכל יום דיל חדש, בכל יום הזדמנות חדשה לחופשה|טיסות ישירות וחבילות נופש ליעדים הכי חמים שיש';
      // document.querySelector('head meta[property="og:description"]').setAttribute('content', bonaufAppDescription);
      // document.querySelector('head meta[name="description"]').setAttribute('content', bonaufAppDescription);
      // metaInfoData.push({ name: 'description', content: bonaufAppDescription });
      // metaInfoData.push({ property: 'og:description', content: bonaufAppDescription });
    } else if (!this.isRegularSite) {
      metaInfoData.push({ name: 'description', content: this.metaDescriptionMarketer });
    }

    return {
      title,
      meta: metaInfoData,
      url: origin,
      script: [
        { src: 'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js', async: true, defer: true },
      ],
    };
  },
  data() {
    return {
      isAgency: true,
      count: 0,
      shownStatusMessage: false,
      statusTitle: '',
      statusMessage: '',
    };
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      statusSite: 'GET_STATUS_MESSAGE',
      isAppDealPage: 'GET_IS_APP_DEAL_PAGE',
      isLanding: 'GET_IS_LANDING_PAGE',
      isProcessingBooking: 'GET_PROCESSING_BOOKING_STATE',
      landingDealData: 'GET_LANDING_DEAL_DATA',
      landingInfo: 'GET_LANDING_INFO',
      lang: 'GET_LANGUAGE',
      packages: 'GET_PACKAGES',
      category: 'GET_CURRENT_CATEGORY',
      product: 'GET_PRODUCT',
      searchContent: 'GET_SEARCH_CONTENT',
      whiteLabel: 'GET_WHITE_LABEL_DATA',
      agencyMarketerContent: 'GET_MARKETER_AGENCY_CONTENT',
      listDestinations: 'GET_LANDING_DESTINATIONS',
      marketerId: 'GET_MARKETER_ID',
      categoryList: 'GET_CATEGORIES',
      typeChannel: 'GET_TYPE_CHANNEL',
      calendarData: 'GET_CALENDAR_DATA',
      isAgencyAgentLogin: 'IS_AGENCY_AUTHORIZED',
      isAgencyLoginState: 'GET_AGENCY_LOGIN_STATE',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      privateMessage: 'GET_PRIVATE_MESSAGE',
      sharedMessage: 'GET_SHARED_MESSAGE',
      // marketer login part
      isLoginMarketer: 'GET_IS_LOGIN_MARKETER',
      isMarketerSite: 'GET_IS_MARKETER_SITE',

      isDesktopApp: 'GET_BONAUF_DESKTOP_APP',
      isMaccabiAgency: 'GET_IS_MACCABI_AGENCY',
    }),
    urlParams() {
      let match;
      const pl = /\+/g,
        search = /([^&=]+)=?([^&]*)/g,
        decode = (s) => decodeURIComponent(s.replace(pl, ' ')),
        query = window.location.search.substring(1);
      const urlParams = {};
      // eslint-disable-next-line no-cond-assign
      while ((match = search.exec(query))) urlParams[decode(match[1])] = decode(match[2]);

      return urlParams;
    },
    isMobileApp() {
      return this.typeChannel === VUE_APP_CHANNEL_MOBILE_APP;
    },
    isRegularSite() {
      return this.marketerId !== '' && this.whiteLabel !== null;
    },
    metaDescriptionMarketer() {
      let description = '';
      if (this.marketerId) {
        description = this.agencyMarketerContent?.metaDescription ?? '';
      } else {
        description = this.whiteLabel?.metaDescription ?? '';
      }
      return description;
    },
    backgroundColor() {
      let color = 'transparent';
      if (this.marketerId !== '') {
        color = this.agencyMarketerContent?.backgroundColorCss ?? 'white';
      } else if (this.whiteLabel && !this.whiteLabel.forMainSite) {
        color = this.whiteLabel?.backgroundColorCss ?? 'white';
      }
      return color;
    },
    kindBonauf() {
      return this.device === 'mobile' ? 'bonauf-mobile' : 'bonauf-desktop';
    },
  },
  watch: {
    $route: {
      immediate: true,
      async handler(to) {
        if (this.$route.name !== null) {
          this.$store.commit('SET_TYPE_CHANNEL', to.query?.channel || '');
          this.$store.dispatch('STORE_APP_DEAL_URL', to);
          if (this.$route.query.marketerId === undefined || this.$route.query.marketerId === '' || this.$route.query.channel === VUE_APP_CHANNEL_MOBILE_APP) {
            const idGtag = this.isMobileApp ? 'GTM-MKMRMKB' : 'GTM-WTKPQ72';
            // window.onesignal_sdk_is_integrated = false;
            // eslint-disable-next-line func-names
            (function (w, d, s, l, i) {
              w[l] = w[l] || []; w[l].push(
                {
                  'gtm.start': new Date().getTime(),
                  event: 'gtm.js',
                },
              );

              const f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l !== 'dataLayer' ? `&l=${l}` : '';

              j.async = true; j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
              f.parentNode.insertBefore(j, f);
            }(window, document, 'script', 'dataLayer', idGtag));
          }

          const gtagID = this.$route.query.channel === VUE_APP_CHANNEL_MOBILE_APP ? 'UA-217589800-1' : 'UA-236052724-1';
          const gtagScript = document.createElement('script');
          gtagScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${gtagID}`);
          document.head.appendChild(gtagScript);
          window.dataLayer = window.dataLayer || [];
          this.gtag('js', new Date());
          this.gtag('config', gtagID);

          // const gtagIDGA4 = 'G-Y4T1ZHCTXH';
          // const gtagScriptGA4 = document.createElement('script');
          // gtagScriptGA4.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${gtagIDGA4}`);
          // document.head.appendChild(gtagScriptGA4);
          // window.dataLayer = window.dataLayer || [];
          // this.gtag('js', new Date());
          // this.gtag('config', gtagIDGA4);
        }

        const accessibility = document.querySelector('.accessibility_component > a.btn_accessibility.accessibility_component');
        if (accessibility) {
          if (to.query?.channel === 'App' && !this.isDesktopApp) {
            accessibility.style.display = 'none';
          } else {
            accessibility.style.display = 'block';
          }
        }

        this.shownStatusMessage = false;
        this.showStatusMessage(to);
      },
    },
    statusSite() {
      this.showStatusMessage(this.$route);
    },
    product() {
      const to = this.$route;
      if (to.name !== 'home') {
        const destination = this.product.destination_1_name,
          dealType = this.getDealTypeName(to.query.categoryId),
          departure = to.query.dateFrom,
          hotel = this.product?.translations?.hotelName?.[to.query.laId]?.en || this.product?.translations?.hotelName?.[to.query.laId]?.he;
        switch (to.name) {
          case 'product-page': case 'flight-only':
            document.title = `השטיח המעופף   {${dealType}} ל {${destination} - ${departure} - ${hotel}}`;
            break;
          default:
        }
      }
    },
    isProcessingBooking() {
      if (this.isProcessingBooking) {
        this.$bvModal.show('processingBooking');
      } else {
        this.$bvModal.hide('processingBooking');
      }
    },
    categoryList() {
      this.$store.dispatch('UPDATE_CURRENT_CATEGORY', { categoryId: this.categoryList[0].dealTypeCode });
    },
    isAgencyLoginState() {
      if (this.isAgencyAgentLogin) {
        window.location.reload();
      }
    },
    isAgencyAgentLogin() {
      if (this.isAgencyAgentLogin) {
        this.$store.dispatch('SOCKET_PRIVATE_CONNECT', this);
        this.$store.dispatch('SOCKET_SHARED_CONNECT', this);
      } else {
        this.$store.dispatch('SOCKET_DIS_CONNECT', this);
      }
    },
    privateMessage() {
      this.showAdminMessageForAgent(this.privateMessage, 'private');
    },
    sharedMessage() {
      this.showAdminMessageForAgent(this.sharedMessage, 'shared');
    },
  },
  methods: {
    showStatusMessage(to) {
      const { location } = window, pageName = to.path;
      if (pageName === location.pathname) {
        if (this.statusSite && this.statusSite?.active && this.statusSite?.target?.package?.length > 0) {
          const pages = this.statusSite.target.package;
          const showStatusMessageSite = pages.includes('allPages') || pages.includes(to.name);
          if (showStatusMessageSite && !this.shownStatusMessage) {
            this.statusTitle = this.statusSite.message?.title ?? '';
            this.statusMessage = this.statusSite.message?.body ?? '';
            this.$bvModal.show('statusSite');
            this.shownStatusMessage = true;
          }
        }
      }
    },
    resize() {
      // let device = 'desktop';
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      // const { offsetWidth } = document.getElementById('app');
      // if (isMobile) {
      //   device = 'mobile';
      // } else {
      //   device = 'desktop';
      // }
      this.$store.dispatch('UPDATE_DEVICE', isMobile ? 'mobile' : 'desktop');
    },
    async sendMailError(pStage, pResponse) {
      const body = `<h3>${pStage}</h3></Br>
        <div><pre>${pResponse}</pre></div>
        `;
      const notification = {
        subject: `${pStage}`,
        body,
      };
      await this.$store.dispatch('NOTIFY_ADMIN', notification);
    },
    getDealTypeName(pCode) {
      let name = pCode;
      switch (pCode) {
        case 'vacation_pack':
          name = 'חבילות נופש';
          break;
        case 'Organize_tour_packages':
          name = 'טיולים מאורגנים';
          break;
        case 'Flight_Only':
          name = 'טיסות בלבד';
          break;
        default:
      }
      return name;
    },
    async getSetSomeParameter() {
      this.$store.commit('SET_FC_AGENT_MARKETER_MODE', this.urlParams['fc-agent-mode'] !== undefined);

      // set MargeterId
      const marketerId = this.urlParams?.marketerId ? this.urlParams?.marketerId : '';
      if (marketerId !== '') {
        this.$store.commit('SET_MARKETER_ID', marketerId);
        await this.$store.dispatch('FETCH_AGENCY_FROM_MARKETER_ID');
      }

      this.$store.commit('SET_HOME_LINK', this.urlParams);
    },
    showAdminMessageForAgent(messageBody, type) {
      const h = this.$createElement;
      const messageContent = h('div', {
        domProps: {
          innerHTML: messageBody,
        },
      });
      const id = `${type}-${this.count += 1}`;
      const closeButton = h(
        'b-button',
        {
          props: { variant: 'primary', size: 'sm' },
          on: { click: () => this.$bvToast.hide(id) },
        },
        'Close',
      );
      this.$bvToast.toast([messageContent, closeButton], {
        id,
        title: `${type} message`,
        variant: 'danger',
        solid: true,
        toaster: 'b-toaster-top-center',
        bodyClass: ['text-right', 'message-content'],
        noCloseButton: true,
      });
    },
    getShareData(data) {
      document.querySelector('head meta[property="og:title"]').setAttribute('content', this.getShareTitleDesc('title', data));
      document.querySelector('head meta[property="og:description"]').setAttribute('content', this.getShareTitleDesc('description', data));
      window.prerenderReady = true;
    },
    getShareTitleDesc(kind, data) {
      const { category, dest, hotel, airline, duration, country, rate } = data;
      let title = '';
      if (category === 'vacation_pack') {
        title = this.$t(`share-this.vacation_pack-${kind}`, { dest, hotel, duration, rate });
      } else if (category === 'Organize_tour_packages') {
        title = this.$t(`share-this.Organize_tour_packages-${kind}`, { country, duration, rate });
      } else if (category === 'Flight_Only') {
        title = this.$t(`share-this.Flight_Only-${kind}`, { dest, airline, duration, rate });
      } else if (category === 'SportPack') {
        title = this.$t(`share-this.SportPack-${kind}`, { dest, hotel, duration, rate });
      } else {
        title = '';
      }

      return title;
    },
  },
  async beforeCreate() {
    /**
     * test link:
     * https://www.flying.co.il/?destination=HER&utm_source=google
     * https://www.flying.co.il/?destination=LCA&utm_source=google&dealtype=Organize_tour_packages
     * https://www.flying.co.il/?destination=PFO&utm_source=google&dealtype=Flight_Only
     * https://www.flying.co.il/?destination=AYT&utm_source=google&dealtype=vacation_pack
     */
  },
  async created() {
    let { host } = window.location;

    await this.$store.dispatch('FETCH_STATUS_MESSAGE');

    if (window.location.pathname === '/redirect-verified') {
      const marketerId = window.parent?.sessionStorage?.getItem('marketerId');
      const typeChannel = window.parent?.sessionStorage?.getItem('typeChannel');
      const paramFcAgentMode = window.parent?.localStorage?.getItem('fcAgentMode');
      this.$store.commit('SET_MARKETER_ID', marketerId);
      let path = window.location.href.replace('redirect-verified', 'booking/card-verified');
      path += `&channel=${typeChannel}`;
      if (marketerId !== '') path += `&marketerId=${marketerId}`;

      // await this.sendMailError(
      //   'Trace email to check the redirection URL: ResponseCR2k',
      //   JSON.stringify({ title: 'ResponseCR2k URL at inner iframe', responseCR2K: decodeURIComponent(path), currPath: decodeURIComponent(window.location.href) }),
      // );

      window.parent.postMessage(`${path}${paramFcAgentMode}`, '*');
      return;
    }
    window.addEventListener('resize', this.resize);

    if (host.includes('localhost')) {
      await this.$store.commit('SET_MODE', true);
      // await this.$store.commit('SET_STAGING_MODE', true);
      await this.$store.commit('SET_TEST_MODE', true);
    }
    if (host.includes(VUE_APP_STAGING_DOMAIN)) {
      // await this.$store.commit('SET_STAGING_MODE', true);
    }
    if (host.includes('new')) {
      await this.$store.commit('SET_AZURE_MODE', true);
    }

    if (host.includes('localhost') || host.includes('vercel')){
      host = 'www.bonauf.co.il';
    }

    const response = await this.$store.dispatch('FETCH_WHITE_LABEL', host);

    const firstPathname = new URL(window.location.href).pathname.split('/')[1];
    if (firstPathname === 'shared') {
      const reverseUrl = await this.$store.dispatch('FETCH_REVERSE_URL', { shortenedUrl: window.location.pathname });
      if (reverseUrl) {
        window.location.href = reverseUrl;
      } else {
        window.location.href = window.location.origin;
      }
    }

    await this.getSetSomeParameter();

    if (response?.data?.status !== 404) {
      this.$store.dispatch('FETCH_CATEGORIES_LIST');
      this.$store.dispatch('FETCH_DESTINATION_IMAGES');
      this.$store.dispatch('FETCH_FOOTER_PAGES_LIST');
    } else {
      this.isAgency = false;
    }
  },
  mounted() {
    this.$root.$on('send-share-data', this.getShareData);
    this.resize();
    loadProgressBar({ showSpinner: false });

    window.addEventListener('message', (event) => {
      const { data } = event;
      if (typeof data === 'string' && (data.includes('booking/card-verified'))) {
        const url = new URL(data);
        // this.sendMailError(
        //   'Trace email to check the redirection URL: ResponseCR2k',
        //   JSON.stringify({ title: 'Redirected URL at outer iframe', URL: decodeURIComponent(url), currPath: decodeURIComponent(window.parent.location.href) }),
        // );
        this.$router.push(`${url.pathname}/${url.search}`);
      }
    });
  },
  destroyed() {
    window.removeEventListener('resize', this.resize);
  },
};
</script>

<style lang="sass">
@import '~bootstrap/scss/bootstrap.scss'
@import '~bootstrap-vue/src/index.scss'

.agile
  &__nav-button
    background: #fff
    border: 1px solid #4285f4
    color: #4285f4
    cursor: pointer
    font-size: 20px
    height: 40px
    position: absolute
    top: calc(50% - 40px)
    transition-duration: .3s
    width: 40px
    border-radius: 50%
    transition: 0.5s all

    &:hover
      border: none
      color: black
      box-shadow: gray -1px 1px 10px
      opacity: 1

    &--prev
      left: 0

    &--next
      right: 0

  &__dots
    bottom: 10px
    left: 50%
    position: absolute
    transform: translateX(-50%)

  &__dot
    margin: 0 10px

    button
      background-color: transparent
      border: 1px solid #fff
      border-radius: 50%
      cursor: pointer
      display: block
      height: 10px
      font-size: 0
      line-height: 0
      margin: 0
      padding: 0
      transition-duration: .3s
      width: 10px

    &--current,
    &:hover
      button
        background-color: #fff
</style>

<style>
@import '/assets/css/bonaufApp.css';

.bonauf-desktop::before {
  content: "";
  background: url(https://cdn.speedsize.com/05b4c650-ae31-47ac-b679-5cd058f0cc68/https://www.flying.co.il/assets/img/app/BO_NAUF.jpg);
  opacity: 0.1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
  overflow: hidden;
}
.b-toaster-top-center .toast:not(.showing):not(.show) {
  opacity: 1;
}
.message-content {
  max-height: 6rem;
  overflow-y: auto;
}
.message-content p{
  margin: 0;
}
.message-content button{
  float:left;
}
#processingBooking + .modal-backdrop {
  opacity: 1;
  background-color: white;
}
#processingBooking .modal-content {
  box-shadow: #cac8c8 1px 1px 20px;
}
.accessibility_component > a.btn_accessibility.accessibility_component {
  /* display: none; */
  width: 80px;
  padding: 5px;
  height: 30px;
}
#processingBooking.ltr {
  direction: ltr;
}
#processingBooking.rtl {
  direction: rtl;
}
#processingBooking .modal-body h5 {
  padding: 35px 20px;
  font-size: 30px;
}
#processingBooking img {
  width: 200px;
  height: auto;
}
#processingBooking h3 {
  font-size: 22px;
  font-weight: 600;
  color: #313131;
  text-align: center;
}
#b-toaster-top-center {
  top: calc(50vh - 70px);
}
#processingBooking.modal .modal-dialog {
  display: flex;
  font-family: 'ploni', 'Poppins', sans-serif;
  font-weight: 500;
}
#processingBooking.modal:before {
  display: none;
}
#processingBooking.modal .modal-body {
  display: flex;
  padding: 0px;
  margin: 0px;
}
.carousel-indicators {
  bottom: -18px;
}
#statusSite {
  padding: 0 !important;
}
#statusSite .modal-header .close {
  padding: 0px;
  margin: 0;
}
@media (max-width:479px){
.carousel-indicators {
  bottom: 0;
}
  #processingBooking img {
    width: 100px;
    height: 100px;
  }
  #processingBooking .modal-body h5 {
    font-size: 22px;
  }
.accessibility_component > a.btn_accessibility.accessibility_component {
  width: 34px;
}
}
#app {
  font-family: 'ploni', 'Poppins', sans-serif;
  text-align: right;
  height: 100%;
}
#app.has-background {
  background-image: url('https://cdn.speedsize.com/05b4c650-ae31-47ac-b679-5cd058f0cc68/https://www.flying.co.il/assets/img/banner_landing.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
html {
  scroll-behavior: smooth;
}
#nprogress .bar {
  background: rgb(0, 255, 149) !important;
  height: 5px;
}
#nprogress .peg {
  box-shadow: 0 0 10px rgb(0, 255, 149), 0 0 5px rgb(0, 255, 149) !important;
}
#nprogress .spinner-icon {
  border-top-color: rgb(0, 255, 149) !important;
  border-left-color: rgb(0, 255, 149) !important;
}
/* .st-content{
  scroll-behavior: smooth;
} */

.b-toast .toast .toast-bod.ltr {
  direction: ltr;
}

.b-toast .toast .toast-bod.rtl {
  direction: rtl;
}
</style>
